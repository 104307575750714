import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { OperationsExportFacade } from '../facades/operations-export.facade';

export function operationsExportCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const operationsExportFacade = inject(OperationsExportFacade);
        operationsExportFacade.clear();
        return true;
    };
}
