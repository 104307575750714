import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, map } from 'rxjs';
import { environment } from '@env';
import { Lang } from '@app/i18n';
import { OperationsExportValue, OperationsExportValueDto } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    exportOperations(value: OperationsExportValue, lang: Lang) {
        const headers = environment.supportedLanguages.includes(lang) ? { 'Accept-Language': lang } : undefined;
        const params = new OperationsExportValueDto(value);

        return this.httpClient
            .addUserSwitch()
            .get(`api/v1/operations/export/${value.type}/v2`, {
                params: { ...params },
                headers,
                responseType: 'blob',
            })
            .pipe(
                map((blob) => ({ value, blob })),
                catchError(() => {
                    throw { value };
                }),
            );
    }
}
