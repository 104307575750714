import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { operationsExportActions } from '../actions/operations-export.actions';
import { HttpService } from '../services/http.service';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OperationsExportEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly messageService = inject(MessageService);
    private readonly translateService = inject(TranslateService);

    exportOperations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(operationsExportActions.exportOperations),
            switchMap(({ params, lang }) => {
                return this.httpService.exportOperations(params, lang).pipe(
                    map((payload) => operationsExportActions.exportOperationsSuccess(payload)),
                    catchError((payload) => of(operationsExportActions.exportOperationsError(payload))),
                );
            }),
        );
    });

    exportOperationsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(operationsExportActions.exportOperationsSuccess),
                tap((payload) => {
                    const url = URL.createObjectURL(payload.blob);
                    const link = document.createElement('a');
                    const name = `operations-${payload.value.dateFrom}-${payload.value.dateTo}`;

                    link.href = url;
                    link.download = name;
                    link.click();

                    link.remove();
                }),
            );
        },
        { dispatch: false },
    );

    exportOperationsError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(operationsExportActions.exportOperationsError),
                tap(() => this.messageService.add(this.translateService.instant('operations.export.error'), 'danger')),
            );
        },
        { dispatch: false },
    );
}
