import { createReducer, on } from '@ngrx/store';
import { operationsExportActions } from '../actions/operations-export.actions';
import { EXPORT_TYPE } from '../types';

export interface OperationsExportState {
    processing: EXPORT_TYPE[];
}

export const initialState: OperationsExportState = {
    processing: [],
};

export const operationsExportReducers = createReducer(
    initialState,
    on(operationsExportActions.exportOperations, (state, payload): OperationsExportState => {
        const processing = [...state.processing, payload.params.type];
        return {
            ...state,
            processing,
        };
    }),
    on(operationsExportActions.exportOperationsSuccess, (state, payload): OperationsExportState => {
        const processing = [...state.processing];
        const index = processing.indexOf(payload.value.type);

        if (index !== -1) {
            processing.splice(index, 1);
        }

        return {
            ...state,
            processing,
        };
    }),
    on(operationsExportActions.exportOperationsError, (state, payload): OperationsExportState => {
        const processing = [...state.processing];
        const index = processing.indexOf(payload.value.type);

        if (index !== -1) {
            processing.splice(index, 1);
        }
        return {
            ...state,
            processing,
        };
    }),
    on(
        operationsExportActions.clear,
        (): OperationsExportState => ({
            ...initialState,
        }),
    ),
);
