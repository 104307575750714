import { OperationsExportValue } from './operations.value';

export class OperationsExportValueDto {
    date_from: string;
    date_to: string;
    search?: string;
    search_column?: string;

    constructor(value: OperationsExportValue) {
        delete this.search;
        delete this.search_column;
        this.date_from = value.dateFrom;
        this.date_to = value.dateTo;
        if (value.search) {
            this.search = value.search;
        }

        if (value.search && value.searchCategory) {
            this.search_column = value.searchCategory;
        }
    }
}
