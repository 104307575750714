import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OPERATIONS_EXPOTY_FEATURE_KEY } from '../keys';
import { OperationsExportState } from '../reducers/operations-export.reducer';
import { EXPORT_TYPE } from '../types';

export const selectState = createFeatureSelector<OperationsExportState>(OPERATIONS_EXPOTY_FEATURE_KEY);

const selectLoading = (key: EXPORT_TYPE) => createSelector(selectState, (state: OperationsExportState) => state.processing.includes(key));

export { selectLoading };
