import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OPERATIONS_EXPOTY_FEATURE_KEY } from './keys';
import { OperationsExportEffects } from './effects/operations-export.effects';
import { operationsExportReducers } from './reducers/operations-export.reducer';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(OPERATIONS_EXPOTY_FEATURE_KEY, operationsExportReducers),
        EffectsModule.forFeature([OperationsExportEffects]),
    ],
})
export class OperationsExportStoreModule {}
