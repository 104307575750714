import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { OperationsExportValue } from '../models';

export const operationsExportActions = createActionGroup({
    source: 'Operations Export',
    events: {
        'Export Operations': props<{ params: OperationsExportValue; lang: Lang }>(),
        'Export Operations Success': props<{ value: OperationsExportValue; blob: Blob }>(),
        'Export Operations Error': props<{ value: OperationsExportValue }>(),

        Clear: emptyProps(),
    },
});
