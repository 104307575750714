import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Lang } from '@app/i18n/lang.type';
import { operationsExportActions } from '../actions/operations-export.actions';
import { OperationsExportValue } from '../models';
import { selectLoading } from '../selectors/operations.selector';
import { EXPORT_TYPE } from '../types';
import { take, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OperationsExportFacade {
    private store = inject(Store);

    readonly processing$ = (type: EXPORT_TYPE) => this.store.select(selectLoading(type));

    readonly processing = (type: EXPORT_TYPE) => {
        let processing = false;

        this.store
            .select(selectLoading(type))
            .pipe(
                take(1),
                tap((value) => (processing = value)),
            )
            .subscribe();

        return processing;
    };

    exportOperations(params: OperationsExportValue, lang: Lang): void {
        this.store.dispatch(operationsExportActions.exportOperations({ params, lang }));
    }

    clear(): void {
        this.store.dispatch(operationsExportActions.clear());
    }
}
